<template>
  <div class="stkp">
    <nav-bar title="生态科普"></nav-bar>
    <div class="tabBar">
      <van-tabs v-model="active" @change="change">
        <van-tab
          v-for="(item, index) in arr"
          :key="index"
          :title="item"
        ></van-tab>
      </van-tabs>
    </div>
    <ul class="main">
      <li
        v-show="item.type == active"
        @click="listClick(item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <img :src="item.pictureUrl" alt="" />
        <h3>{{ item.name || "未知" }}</h3>
      </li>
    </ul>
  </div>
</template>
<script>
import { getData } from "@/network/stkp.js";
import NavBar from "@/components/content/navBar/NavBar.vue";
// import list from "../../common/stkp.js";
export default {
  data() {
    return {
      active: 0,
      current: 0,
      arr: ["一级保护动物", "二级保护动物"],
      list: null,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const result = await getData();
      this.list = result.data;
    },
    itemClick(index) {
      this.current = index;
    },
    listClick(item) {
      this.$router.push({
        name: "Content",
        query: { item: JSON.stringify(item) },
      });
    },
    change(value) {
      console.log(value);
      console.log(this.active);
    },
  },
  components: {
    NavBar,
  },
};
</script>
<style scoped>
.stkp {
  padding-top: 0.88rem;
}
ul {
  padding-left: 0.3rem;
  margin-top: 1.03rem;
}
li {
  width: 3.34rem;
  display: inline-block;
  padding-right: 0.25rem;
  margin-bottom: 0.3rem;
}
li img {
  width: 100%;
  height: 2.2rem;
  border-radius: 5px;
}
li h3 {
  font-size: 0.28rem;
  font-family: PingFang SC;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tabBar {
  width: 100%;
  height: 0.66rem;
  font-size: 0.28rem;
  display: flex;
  position: fixed;
  left: 0;
  top: 0.88rem;
  text-align: center;
  background: url("~assets/img/home/微信图片_20201229214626.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
}
.tabBar .item {
  flex: 1;
  font-weight: bold;
  color: #9c4c39;
  line-height: 0.66rem;
  transition: 0.3s;
}
.tabBar .itemActive {
  color: burlywood;
}

.van-tabs {
  width: 100%;
  display: flex;
  height: 0.66rem;

}
.van-tabs >>> .van-tabs__wrap {
  width: 100%;
  height: 100%;

}
.van-tab {
  flex: 1;
}
</style>